import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { connect } from 'react-redux'
import { setImg, setAlt, setDescription, setCaption } from '../state/app'
import { Gallery } from "react-grid-gallery";
import 'react-multi-carousel/lib/styles.css';
import CLICK from '../images/click.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHandPointer
} from '@fortawesome/free-solid-svg-icons'
import "@fortawesome/fontawesome-svg-core/styles.css"



const GetPublicPath = (src) => {
    const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            name
            relativePath
            extension
            publicURL
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, JPG]
                    quality: 100
                )
            }
          }
        }
      }
    }
  `)

    const match = useMemo(
        () => data.images.edges.find(({ node }) => src === node.relativePath),
        [data, src]
    )

    if (!match) return null

    const { node: { childImageSharp, publicURL, extension } = {} } = match

    return publicURL

}

const GalleryComponent = ({ dispatch, carousel, products }) => {


    var array_images = []
    carousel.map((img) => (
        array_images.push({ "src": GetPublicPath(img.src), 'internalSrc': img.src, "caption": img.caption, "width": "100%", "height": img.height, "link": img.link })
    )
    )

    var images = array_images.map(image => {
        var productFind = products.filter(product => product.image == image.internalSrc)

        image.customOverlay = (
            <div className="custom-overlay__caption" onClick={() => ( dispatch(setImg(image.internalSrc)), dispatch(setAlt(productFind.alt)),
                dispatch(setDescription(productFind.info.description)), dispatch(setCaption(productFind.info.caption)),
                navigate(image.link))}

                onKeyDown={() => ( dispatch(setImg(image.internalSrc)), dispatch(setAlt(productFind.alt)),
                    dispatch(setDescription(productFind.info.description)), dispatch(setCaption(productFind.info.caption)),
                    navigate(image.link))} >
                <div>{image.caption}</div>
            </div>
        );
        return image;
    });


    const handleSelect = (index) => {
        var productFind = products.filter(product => product.image == images[index].internalSrc)
        dispatch(setImg(images[index].internalSrc))
        dispatch(setAlt(productFind.alt))
        dispatch(setDescription(productFind[0].info.description))
        dispatch(setCaption(productFind[0].info.caption))
        navigate(images[index].link);
    };


    return (
        <>
            <GalleryStyled img={CLICK}>
                <FontAwesomeIcon icon={faHandPointer} size="2x" className='fa-beat icon-fixed' />
                <Gallery images={images} enableImageSelection={true} className="grid" onClick={handleSelect} />
            </GalleryStyled>
        </>
    )
}
export default connect(state => ({
    useImg: state.app.img,
    useAlt: state.app.alt,
    useDescription: state.app.description,
    useCaption: state.app.caption
}), null)(GalleryComponent)


const GalleryStyled = styled.div`
position: relative;
max-width: 1920px;
margin: 0 auto;
padding: clamp(30px,10vw,90px) 5% clamp(30px,10vw,60px) 5%;

.icon-fixed{
    position: sticky;
    z-index: 99;
    left: auto;
    top: 25vh;
    float: right;
    margin-top: 5vh;
    --fa-beat-fade-opacity: 0.67; 
    --fa-beat-fade-scale: 1.075;
}
.pointer{
    cursor: pointer;
}
.ReactGridGallery:first-child {
    display: grid !important;
}
#ReactGridGallery .ReactGridGallery_tile, #ReactGridGallery img{
    height: 30vh !important;
    object-fit: cover;
    cursor: pointer !important;
}

div[data-testid="grid-gallery-item_thumbnail"]{
    cursor: auto !important;
}
.ReactGridGallery_tile-icon-bar{
    pointer-events: visible;
    opacity: 1;
    position: absolute;
    height: 36px;
    width: 100%;
    bottom: 0;
    top: auto;
    height: 100% !important;
    cursor: none;
}

div[title="Select"]{
    float: left !important;
    width: 25% !important;
    height: 25% !important;
    border: none !important;
    padding: 6px !important;
    position: relative !important;
    text-align: center !important;
    top: 45% !important;
    z-index: 99;
    margin-left: 35%;
    margin-right: auto;
    svg path:nth-of-type(2){
        fill: #eeefed;
        clip-path: path("M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z") !important;
        d: path("M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z") !important;
    }
    display: none !important;
}

.ReactGridGallery_tile-viewport{
    height: 100% !important;
    img{
        cursor: auto !important;
    }
}
.ReactGridGallery_tile img{
    width: 100%  !important;
}

.ReactGridGallery_custom-overlay{
    background: rgb(0, 0, 0, 0.4) !important;
    -webkit-transition: background 1000ms ease-in, opacity 100ms ease-in;
    -ms-transition: background-color 1000ms ease-in, opacity 100ms ease-in;
    transition: background 100ms ease-in, opacity 100ms ease-in;
    background-color: yellowgreen;
  -webkit-transition: background-color 2s ease-in; /* Safari */
          transition: background-color 2s ease-in;
  -webkit-transition-delay: 2s; /* Safari */
          transition-delay: 2s;
    cursor: pointer !important;
}

.custom-overlay__caption{
    font-size: 2vh;
    color: #eeefed;
    filter: opacity(1) !important;
}



.ReactGridGallery_tile:nth-child(n) {
    width: 35% ;
}
.ReactGridGallery_tile:nth-child(2n) {
    width: 28% ;
}
 .ReactGridGallery_tile:nth-child(6n-2) {
    width: 30% ;
}
.ReactGridGallery_tile:nth-child(6n-1) {
    width: 38% ;
}
.ReactGridGallery_tile:nth-child(6n) {
    width: 30% ;
}

@media (max-width: 990px){
    text-align: center;
    .icon-fixed{
        font-size: 2vh !important;
    }
    .ReactGridGallery_tile {
        width: 100%;
        margin: 2px !important;
    }
    .ReactGridGallery_tile:nth-child(4n+1) {
        width: 57%;
    }
    .ReactGridGallery_tile:nth-child(4n+2) {
        width: 40%;
    }
    .ReactGridGallery_tile:nth-child(4n+3) {
        width: 40% !important;
    }
    .ReactGridGallery_tile:nth-child(4n+4) {
        width: 57% !important;
    }
}
`